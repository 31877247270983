












































































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import CadastroUsuarioDTO from "@/domains/usuario/CadastroUsuarioDTO";
import CadastroUsuarioBO from "@/domains/usuario/CadastroUsuarioBO";
import AutenticacaoAPI from "@/domains/autenticacao/AutenticacaoAPI";
import CadastroUsuarioFactory from "@/domains/usuario/CadastroUsuarioFactory";
import UsuarioAPI from "@/domains/usuario/UsuarioAPI";
import ErrorHandling from "@/domains/application/ErrorHandling";
import LocalStorageProvider, {
  LocalStorageEnum,
} from "@/providers/LocalStorageProviders";
import ConfigDTO from "@/domains/application/ConfigDTO";
import md5 from "md5";
import { MaskHelper } from "@/domains/application/MaskHelper";
import jwt_decode from "jwt-decode";
import JWTDTO from "@/domains/application/JWTDTO";

@Component
export default class CadastroUsuario extends Vue {
  mostrarSenha = false;
  mostrarConfirmarSenha = false;
  usuario: CadastroUsuarioDTO = {
    nome: "",
    sobrenome: "",
    email: "",
    celular: "",
    cpf: "",
    ativo: "",
    senha: "",
    id_termo_uso_plataforma: 0,
    apelido: "",
  };
  confirmarSenha = "";
  codigo_ativacao = "";
  cadastroUsuario = true;
  ativarConta = false;
  modoLoginSelecionado = "";
  cpfLogin = "";
  senhaLogin = "";
  realizandoLogin = false;

  @Watch("modoLoginSelecionado")
  onChangeModoLoginSelecionado() {
    this.cpfLogin = "";
    this.senhaLogin = "";
    this.usuario = {
      nome: "",
      sobrenome: "",
      email: "",
      celular: "",
      cpf: "",
      ativo: "",
      senha: "",
      id_termo_uso_plataforma: 0,
      apelido: "",
    };
  }

  async cadastrarUsuario() {
    try {
      CadastroUsuarioBO.validateCadastroUsuario(
        this.usuario,
        this.confirmarSenha
      );
      const usuario = CadastroUsuarioFactory.buildCadastroUsuario(this.usuario);
      await new UsuarioAPI().cadastrarUsuario(usuario);
      this.cadastroUsuario = false;
      this.ativarConta = true;
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async ativacaoConta() {
    try {
      const cpf = MaskHelper.cleanMaskCPF(this.usuario.cpf);
      await new UsuarioAPI().ativarUsuario(cpf, this.codigo_ativacao);

      const response = await new AutenticacaoAPI().autenticarUsuario(
        cpf,
        md5(this.usuario.senha)
      );
      const token = response.data.token;
      if (!token) return;
      const decodedToken = jwt_decode(token);
      const decodedTokenObject = decodedToken as JWTDTO;
      LocalStorageProvider.store<ConfigDTO>(
        { jwt: token, id_usuario: decodedTokenObject.id_usuario },
        LocalStorageEnum.CONFIG_KEY
      );
      this.cadastroUsuario = false;
      this.ativarConta = false;
      this.$store.dispatch(
        "contratacaostate/setid_usuario",
        Number(decodedTokenObject.id_usuario)
      );
      this.$store.dispatch("contratacaostate/setusuario_logado", true);
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async realizarLogin() {
    try {
      if (this.realizandoLogin) return;
      this.realizandoLogin = true;
      const cpf = MaskHelper.cleanMaskCPF(this.cpfLogin);
      const response = await new AutenticacaoAPI().autenticarUsuario(
        cpf,
        md5(this.senhaLogin)
      );
      const token = response.data.token;
      if (!token) return;
      const decodedToken = jwt_decode(token);
      const decodedTokenObject = decodedToken as JWTDTO;
      LocalStorageProvider.store<ConfigDTO>(
        { jwt: token, id_usuario: decodedTokenObject.id_usuario },
        LocalStorageEnum.CONFIG_KEY
      );

      this.cadastroUsuario = false;
      this.ativarConta = false;

      this.$store.dispatch(
        "contratacaostate/setid_usuario",
        Number(decodedTokenObject.id_usuario)
      );
      this.$store.dispatch("contratacaostate/setusuario_logado", true);
      this.realizandoLogin = false;
    } catch (error) {
      this.realizandoLogin = false;
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }
}
