


















import ContratacaoDTO from '@/domains/contratacao/ContratacaoDTO'
import { NavegacaoContratacao } from '@/domains/contratacao/ContratacaoEnum'
import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import FinalizacaoCotacao from './FinalizacaoCotacao.vue'
import InformacoesCotacao from './InformacoesCotacao.vue'
import LocalCotacao from './LocalCotacao.vue'
import ProdutosCotacao from './ProdutosCotacao.vue'


@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", [
      "setRegimeTributario",
    ]),
  },
  components: {
    InformacoesCotacao,
    LocalCotacao,
    ProdutosCotacao,
    FinalizacaoCotacao
  }
})
export default class CadastroCotacao extends Vue {
  contratacao!: ContratacaoDTO;
  navegacaoContratacao = NavegacaoContratacao

}

