



















 

import ContratacaoDTO from '@/domains/contratacao/ContratacaoDTO'
import { PlanosEnum } from '@/domains/planos/PlanosEnum'
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
})
export default class NavegacaoCadastroConta extends Vue {
  contratacao!: ContratacaoDTO
  planosEnum = PlanosEnum

}

