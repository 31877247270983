








import { Component, Vue, Watch } from "vue-property-decorator"
import { mapGetters } from "vuex"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import CadastroConta from "@/components/contratacao/conta/CadastroConta.vue"
import CadastroFrete from "@/components/contratacao/frete/CadastroFrete.vue"
import CadastroCotacao from "@/components/contratacao/cotacao/CadastroCotacao.vue"
import NavegacaoCadastroFrete from "@/components/contratacao/frete/NavegacaoCadastroFrete.vue"
import NavegacaoCadastroCotacao from "@/components/contratacao/cotacao/NavegacaoCadastroCotacao.vue"
import NavegacaoCadastroConta from "@/components/contratacao/conta/NavegacaoCadastroConta.vue"
import CadastroUsuario from "@/components/cadastroUsuario/CadastroUsuario.vue"
import LocalStorageProvider, { LocalStorageEnum } from "@/providers/LocalStorageProviders"
import ConfigDTO from "@/domains/application/ConfigDTO"
import { PlanosEnum, PlanosRamosEnum } from "@/domains/planos/PlanosEnum"
import ErrorHandling from "@/domains/application/ErrorHandling"
import PlanosAPI from "@/domains/planos/PlanosAPI"

@Component({
  components: {
    CadastroConta,
    CadastroFrete,
    CadastroCotacao,
    NavegacaoCadastroFrete,
    NavegacaoCadastroCotacao,
    NavegacaoCadastroConta,
    CadastroUsuario,
  },
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
})
export default class Contratacao extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastros = NavegacaoContratacaoTipoCadastro
  token = LocalStorageProvider.retrieve<ConfigDTO>(LocalStorageEnum.CONFIG_KEY).jwt
  idUsuario = LocalStorageProvider.retrieve<ConfigDTO>(LocalStorageEnum.CONFIG_KEY).id_usuario
  carregandoPlano = false

  mounted() {
    const usuarioLogado = this.token && this.idUsuario ? true : false
    this.$store.dispatch("contratacaostate/setusuario_logado", usuarioLogado)

    if (usuarioLogado)
      this.$store.dispatch("contratacaostate/setid_usuario", Number(this.idUsuario))

  }

  getIdByPlan() {
    const planIds = {
      SPOT: () => 4,
      STANDARD: () => 1,
      ADVANCED: () => 2,
      PREMIUM: () => 3,
      GRATIS: () => 5,
      TRANSPORTADORA_STANDARD: () => 6,
      TRANSPORTADORA_ADVANCED: () => 7,
      TRANSPORTADORA_PREMIUM: () => 8,
    }
    let planoSelecionado = this.contratacao.plano_selecionado as keyof typeof planIds
    return planIds[planoSelecionado]()
  }

  async preparePlans() {
    if (this.contratacao.plano_selecionado === PlanosEnum.TRIAL) this.$store.dispatch("contratacaostate/settipo", PlanosRamosEnum.TOMADOR)
    if (this.contratacao.plano_selecionado !== PlanosEnum.TRIAL) this.buscarPlanoSelecionado()
  }

  async buscarPlanoSelecionado() {
    this.carregandoPlano = true
    try {
      const response = await new PlanosAPI().searchPlanById(this.getIdByPlan())
      this.$store.dispatch("contratacaostate/setplano_selecionado_completo", response.data)
      this.carregandoPlano = false
    } catch (error) {
      this.carregandoPlano = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  @Watch("contratacao.usuario_logado")
  onChangeUsuarioLogado(newValue: boolean, oldValue: boolean) {
    if (!newValue) return
    if (newValue === oldValue) return
    this.preparePlans()
  }


}
