
















import ContratacaoDTO from '@/domains/contratacao/ContratacaoDTO'
import { NavegacaoContratacao } from '@/domains/contratacao/ContratacaoEnum'
import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import FinalizacaoFrete from './FinalizacaoFrete.vue'
import InformacoesFrete from './InformacoesFrete.vue'
import LocalFrete from './LocalFrete.vue'
import ProdutosFrete from './ProdutosFrete.vue'

@Component({
  components: {
    FinalizacaoFrete,
    InformacoesFrete,
    LocalFrete,
    ProdutosFrete
  },
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", [
      "setRegimeTributario",
    ]),
  },
})
export default class CadastroFrete extends Vue {
  contratacao!: ContratacaoDTO;
  navegacaoContratacao = NavegacaoContratacao

}

