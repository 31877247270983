















import ContratacaoDTO from '@/domains/contratacao/ContratacaoDTO'
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
})
export default class NavegacaoCadastroFrete extends Vue {
  contratacao!: ContratacaoDTO

}

